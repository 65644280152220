import React from 'react'
import Slider from 'react-slick';
import imageData from '../ImagesData'
import { BiSolidBriefcase } from 'react-icons/bi'
import { MdLocationPin } from 'react-icons/md'
import { RiGraduationCapFill } from 'react-icons/ri'

export default function JobCarousel({ activeTab, handleShow1, setShowPosition, course }) {


    let slides;

    console.log("course in JobC", course);

    console.log("activeTab in JobC", activeTab);

    course.length < 3 ? slides = course.length : slides = 3

    // Slick carousel settings
    const settings = {
        // dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: slides, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 3000, // Transition speed in milliseconds
        autoplaySpeed: 3000, // Interval between each slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            {course.length > 0 ?
                <div className='career-carousel'>
                    <Slider {...settings}>
                        {course.map(a => {
                            return (
                                <div className='d-flex justify-content-center'>
                                    <div className="card career-cards" key={a.id}>
                                        <div className='d-flex justify-content-center'>
                                            <img src={imageData.emailLogo} alt='' className='carousel-main' />
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title">{a.position}</h1>
                                            <h2 className="card-text"><span><BiSolidBriefcase /></span>{a.experience}</h2>
                                            <p className="card-text p1"><span><MdLocationPin /></span>{a.location}</p>
                                            <p className="card-text p2"><span><RiGraduationCapFill /></span>{a.qualification}</p>
                                        </div>
                                        <button className="btn career-button mt-2"
                                            onClick={() => {
                                                setShowPosition(a.position)
                                                handleShow1()
                                            }}>Apply Now</button>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div> : <h1 className='text-center mt-5'>Currently no job opening available for {activeTab}</h1>
            }

        </>
    )
}
