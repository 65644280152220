import React, { useEffect, useState } from 'react'
import '../Styles/Footer.css'
import companylogo2 from '../Images/SUMAGO Logo (2) (1) 3.png'
import emailLogo from '../Images/emailLogo.png'
import locationLogo from '../Images/locationLogo.png'
import phoneLogo from '../Images/phoneLogo.png'
import { BiLogoInstagram, BiLogoFacebookCircle } from 'react-icons/bi'
import { LiaLinkedin } from 'react-icons/lia'
import { BsFillSuitHeartFill } from 'react-icons/bs'
import axios from 'axios'
import { toast } from "react-toastify";
import Header from './Header'
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from 'react'


export default function Footer() {

    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [officeDetails, setOfficeDetails] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const captchaRef = useRef(null);
    const onChange = (value) => {
        // This function will be called when the ReCAPTCHA is completed.
        setCaptchaVerified(true);
        console.log(value);
    }

    useEffect(() => {
        axios.get('contact_details/get-all-record')
            .then(res => {
                console.log("res.data in contact details", res.data);
                setOfficeDetails(res.data)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!isCaptchaVerified) {
            setSubmitMessage("Please complete the ReCAPTCHA before submitting.");
            return;
        }

        setIsSubmitting(true);

        setTimeout(() => {
            // Reset the form data, ReCAPTCHA, and submit message.
            setCaptchaVerified(false);
            setSubmitMessage("");
            setIsSubmitting(false);
            // Reset the ReCAPTCHA component using the ref.
            if (captchaRef.current) {
                captchaRef.current.reset();
            }
        }, 2000);

        const newData = { name, mobile_no: mobile, email, messege: message }
        axios.post(`contact/add`, newData)
            .then(res => {
                console.log(res.data);
                toast.success("Form submitted successfully")
            }).catch(err => {
                console.log(err);
                toast.error("Something went wrong")
            })
        setName("")
        setMessage("")
        setMobile("")
        setEmail("")
    }

    // Perform email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const mobileRegex = /^\d{10}$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!emailRegex.test(e.target.value)) {
            e.target.setCustomValidity('Email is incorrect.');
        } else {
            e.target.setCustomValidity('');
        }
    };


    const handleMobileChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        setMobile(truncatedValue);

        if (numericValue.length > 11) {
            e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
        } else {
            e.target.setCustomValidity('');
        }
    };

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Filter out non-alphabetic characters
        const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
        e.target.value = alphabeticValue;
        setName(alphabeticValue);
        
        // Check if the input contains only alphabets
        if (inputValue !== alphabeticValue) {
          e.target.setCustomValidity('Name should only contain alphabets.');
        } else {
          e.target.setCustomValidity('');
        }
      };

      const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        // Filter out non-alphabetic characters
        const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
        e.target.value = alphabeticValue;
        setMessage(alphabeticValue);
        
        // Check if the input contains only alphabets
        if (inputValue !== alphabeticValue) {
          e.target.setCustomValidity('Message should only contain alphabets.');
        } else {
          e.target.setCustomValidity('');
        }
      };

    return (
        <>
            <Header />
            <div className='mt-4'>
                <div className='footer-main row'>
                    <div className='div-1 col-2 d-flex align-items-center justify-content-center'>
                        <h1>CONTACT US</h1>
                    </div>
                    <div className='div-2 col-10'>
                        <div className='div-2-main-content row'>
                            <div className='col-12 col-md-12 col-lg-6 info'>
                                <div>
                                    <h1><span><img src={phoneLogo} alt="" className='logo contact-logo img-fluid' /></span><a href="tel:+91 8530648814" className='text-dark text-decoration-none'>+91 8530648814</a> <br />
                                        <a href="tel:+91 8530388815" className='text-dark text-decoration-none'>+91 8530388815</a></h1>
                                    <h1 className='mt-5 contact-email'><span><img src={emailLogo} alt="" className='logo email-logo img-fluid' /></span><a href="mailto:info@sumagoinfotech.com" className='text-dark text-decoration-none'>info@sumagoinfotech.com</a></h1>
                                    {/* <h1 className='mt-5 contact-location'><span><img src={locationLogo} alt="" className='logo location-logo img-fluid' /></span>3rd floor , Gajara chambers , Mumbai – Agra Highway , Kamod Nagar , Nashik Maharashtra 422009 INDIA</h1> */}
                                    {/* <h1 className='mt-5'>302, 3rd floor , Kakade Center Port, Near
                                        E-Square, University Road , Shivajinagar , Pune Maharashtra, 411016 INDIA</h1>
                                    <h1 className='mt-5'>4358 Cresthave Drive , Westlake , California 1362 , USA</h1> */}
                                    {officeDetails.length > 0 &&
                                        <>
                                            {officeDetails.map((details, index) => {
                                                return (
                                                    <h1 className={`mt-5 ${index === 0 ? 'contact-location' : ''}`}><span><img src={locationLogo} alt="" className='logo location-logo img-fluid' /></span>{details.address}</h1>
                                                )
                                            })}
                                        </>
                                    }
                                    <img src={companylogo2} alt="" srcset="" className='mt-5 img-fluid company-logo-footer' />
                                </div>
                            </div>
                            <div className='col-12 col-md-12 col-lg-6 form'>
                                <form className='form-data-1' onSubmit={handleSubmit}>
                                    <h1>GET IN TOUCH</h1>
                                    <input type="text" name="Name" className="form-data-1-name"
                                        placeholder='Name' required
                                        onChange={handleNameChange} value={name} />
                                    <input type="text" name="Email" className="form-data-1-email"
                                        placeholder='Email' required
                                        onChange={handleEmailChange} value={email} />
                                    <input type="text" name="Mobile" className="form-data-1-email"
                                        placeholder='Mobile No' required
                                        onChange={handleMobileChange} value={mobile} />
                                    <textarea name="" id="" rows={2}
                                        placeholder='Message'
                                        className='form-data-1-message' required
                                        onChange={handleMessageChange} value={message} />
                                    <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey="6Lc-cwEpAAAAAIln-wa3CB5qrsM7DGh7FG6icrYj"
                                        onChange={onChange} className='mt-4 recaptcha'
                                    />
                                    <button type="submit" className='btn btn-danger w-100 mt-3 form-data-1-button'> {isSubmitting ? "Submitted" : "Send"}</button>
                                    {submitMessage && <p>{submitMessage}</p>}
                                    {/* <button type="submit" className='btn btn-danger w-100 mt-3 form-data-1-button'>Send</button> */}
                                </form>
                                <div className='subscribe'>
                                    {/* <input type="text" />
                            <button type="submit" className='btn btn-danger'>Subscribe</button>
                            <h1>Join Our NewsLetter</h1> */}
                                    <div className='social-media-icons d-flex justify-content-evenly'>
                                        <a href="https://www.instagram.com/sumago_infotech/"
                                            target="_blank" rel="noopener noreferrer" >
                                            <BiLogoInstagram fill='red' />
                                        </a>
                                        <a href="https://www.facebook.com/sumagoinfotech/"
                                            target="_blank" rel="noopener noreferrer" >
                                            <BiLogoFacebookCircle fill='red' />
                                        </a>
                                        <a href="https://in.linkedin.com/company/sumago-infotech-pvt-ltd"
                                            target="_blank" rel="noopener noreferrer" >
                                            <LiaLinkedin fill='red' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footer-copyright-content d-flex justify-content-evenly'>
                            <div className='end-footer'>
                                <p>With <BsFillSuitHeartFill fill='red' /> From Nashik</p>
                            </div>
                            <div>
                                <p className='rights'>@2023 All Rights Registered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
