import Header from "./Header";
import NewFooter from "./NewFooter";
// import DigitalMarketing from '../Images/ServicePageImages/DigitalMarketing.png'
// import ITConsulting from '../Images/ServicePageImages/ITConsulting.png'
// import MobileApplication from '../Images/ServicePageImages/MobileApplication.png'
// import WebDevelopment from '../Images/ServicePageImages/WebDevelopment.png'
import { useEffect, useState } from "react";
import '../Styles/NewServicePage.css'
import axios from "axios";


export default function NewServicePage() {

    const [serviceDesktop, setServiceDesktop] = useState([])
    const [serviceMobile, setServiceMobile] = useState([])

    // const ImagesArray = [
    //     {
    //         image: DigitalMarketing,
    //         title: "Digital-Marketing"
    //     },
    //     {
    //         image: ITConsulting,
    //         title: "IT-Consulting"
    //     },
    //     {
    //         image: MobileApplication,
    //         title: "Mobile-Application"
    //     },
    //     {
    //         image: WebDevelopment,
    //         title: "Web-Development"
    //     },
    // ]

    useEffect(() => {
        axios.get('/service/get-all-record')
            .then(res => {
                console.log("res.data in services", res.data);
                const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
                console.log("data in mobileType", mobileImage);
                setServiceMobile(mobileImage)

                const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
                console.log("data in desktopType", desktopImage);
                setServiceDesktop(desktopImage)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 500)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.addEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="new-service-main">
            <Header />
            <div className="mt-2 image-main">
                {serviceDesktop.length > 0 &&
                    <>
                        {isMobileView ?
                            serviceMobile.map((service, index) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <img src={service.service_image} alt={`${service.title}-img`}
                                            className={`${index !== 0 ? "mt-2" : "mt-0"} img-fluid`} />
                                    </div>
                                )
                            }) : serviceDesktop.map((service, index) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <img src={service.service_image} alt={`${service.title}-img`}
                                            className={`${index !== 0 ? "mt-2" : "mt-0"} img-fluid`} />
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
            <div className="mt-2">
                <NewFooter />
            </div>
        </div>
    )
}