import React, { useEffect, useState } from "react"
import '../Styles/Newfooter.css'
// import IndianFlag from '../Images/indiaLogo.png'
// import AmericanFlag from '../Images/americaLogo.png'
import { BsFillSuitHeartFill, BsFillTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { Link } from "react-router-dom"
import { IoHome } from 'react-icons/io5'
import axios from "axios"

function NewFooter() {

    function scrollToTop() {
        window.scrollTo(0, 0)
    }

    const [officeDetails, setOfficeDetails] = useState([])

    useEffect(() => {
        axios.get('contact_details/get-all-record')
            .then(res => {
                console.log("res.data in contact details", res.data);
                setOfficeDetails(res.data)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    // const ourOfficeLocations = [
    //     {
    //         title: "CORPORATE OFFICE",
    //         address: "3rd floor , Gajara chambers , Mumbai – Agra Highway , Kamod Nagar , Nashik Maharashtra 422009 INDIA",
    //         image: IndianFlag,
    //         email_id: "info@sumagoinfotech.com",
    //         mobile_no: "+91 8530388815"
    //     },
    //     {
    //         title: "PUNE OFFICE",
    //         address: "302, 3rd floor , Kakade Center Port, Near E-Square, University Road , Shivajinagar , Pune Maharashtra, 411016 INDIA",
    //         image: IndianFlag,
    //         email_id: "info@sumagoinfotech.com",
    //         mobile_no: "+91 7263084881"
    //     },
    //     {
    //         title: "USA OFFICE",
    //         address: "4358 Cresthave Drive , Westlake , California 1362 , USA",
    //         image: AmericanFlag,
    //         email_id: "info@sumagoinfotech.com",
    //         mobile_no: "+91 9890058156"
    //     }
    // ]

    return (
        <div className="NewFooter-main">
            <div className="footer-section">
                <div className="row first-row-main">
                    <div className="our-company col-md-3 col-sm-12">
                        <div className="text-md-start text-center">
                            <h1>Sumago Infotech</h1>
                        </div>
                        <div className="mt-5 text-md-start text-center">
                            <p>
                            At Sumago Infotech, we "Strive with Technology" to provide the most effective and affordable services that fulfill our customer's needs within budget. We provide customized websites and software solutions tailored to the customers' company objectives.
                            </p>
                            {/* <p>Sumago Infotech is a website design studio that offers corporate web design</p> */}
                        </div>
                    </div>
                    <div className="our-products col-md-6 col-sm-12 mt-md-0 mt-5">
                        <div className="ms-0">
                            <h1 className="text-center ms-0">Services</h1>
                        </div>
                        <div className="mt-5 row  ms-0">
                            <div className="col-sm-4 col-12 text-md-start text-center">
                                <p className="ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Digital Marketing
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Web Development
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Mobile Application
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        IT Consulting
                                    </Link>
                                </p>
                            </div>
                            <div className="col-sm-4 col-12 text-md-start text-center">
                                <p className=" ms-lg-5 ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        IT Solutioning
                                    </Link>
                                </p>
                                <p className="ms-lg-5 ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Blockchain
                                    </Link>
                                </p>
                                <p className="ms-lg-5 ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Data Analytics
                                    </Link>
                                </p>
                                <p className="ms-lg-5 ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Artificial Intelligence
                                    </Link>
                                </p>
                            </div>
                            <div className="col-sm-4 col-12 text-md-start text-center">
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Outsourcing Engagement
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Project Management
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        Resource Augmentation
                                    </Link>
                                </p>
                                <p className=" ms-0">
                                    <Link className="text-decoration-none text-white" to='/services'
                                        onClick={scrollToTop}
                                    >
                                        IOT
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12">
                        <div>
                            <h1 className="ms-5">Services</h1>
                        </div>
                    </div> */}
                    <div className="our-contact ms-lg-5 col-md-2 col-sm-12 mt-md-0 mt-5">
                        <div className="text-md-start text-center">
                            <h1>Contact</h1>
                        </div>
                        <div className="mt-5 text-md-start text-center">
                            <a href="tel:+91 8530648814" className="text-white text-decoration-none"><p>+91 8530648814</p></a>
                            {/* <a href="tel:+91 9890058156" className="text-white text-decoration-none"><p>+91 9890058156</p></a> */}
                            <a href="mailto:info@sumagoinfotech.com" className="text-white text-decoration-none"><p>info@sumagoinfotech.com</p></a>
                        </div>
                    </div>
                </div>

                <hr style={{ color: "white", border: "1px solid white" }} />

                <div className="our-office mt-5">
                    <h1>OUR OFFICES</h1>
                </div>

                {officeDetails.length > 0 &&
                    <>
                        <div className="row second-row-main mt-md-5 mt-0">
                            {officeDetails.map((ourLocation, index) => {
                                return (
                                    <div className={`col-lg-4 col-12 location-main ms-md-4 ms-0 mt-5 mt-lg-0`}>
                                        <h1>{ourLocation.title}</h1>
                                        <p className="mt-5 address"><IoHome className="me-2 mb-2" />{ourLocation.address}</p>
                                        <a href={`mailto:${ourLocation.email_id}`} className="text-white text-decoration-none">
                                            <p className={`email-${index}`}><MdEmail className="me-2 mb-2" />{ourLocation.email_id}</p>
                                        </a>
                                        <a href={`tel:${ourLocation.mobile_no}`} className="text-white text-decoration-none">
                                            <p className={`contact-${index}`}><BsFillTelephoneFill className="me-2 mb-2" />{ourLocation.mobile_no}</p>
                                        </a>
                                        <img src={ourLocation.image} alt=""
                                            className={`img-fluid cicon-${index}`}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }


                <div className="d-flex justify-content-center with-love-section">
                    <h2>@2023, Made by Sumago Infotech with <BsFillSuitHeartFill fill="red" className="ms-2 me-2" /> From Nashik</h2>
                </div>

                {/* <div className="d-flex justify-content-end copyright-section">
                        <h5> @2023 All Rights Reserved </h5>
                    </div> */}
            </div>
        </div>
    )
}

export default NewFooter