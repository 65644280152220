import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
;

export default function AchievementCarousel() {

    const [achievement, setAchievement] = useState([])

    useEffect(() => {
        axios.get(`achievement/get-all-record`)
            .then(res => {
                console.log(res.data);
                setAchievement(res.data)
            }).catch(err => {
                console.log(err);
            })
    }, [])

    let slides;

    achievement.length < 3 ? slides = achievement.length : slides = 3

    // Slick carousel settings
    const settings = {
        // dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: slides, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 3000, // Transition speed in milliseconds
        autoplaySpeed: 3000, // Interval between each slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    console.log("Achievement pics", achievement);

    return (
        <div>
            <div className='achievement-carousel-main'>
                {achievement.length > 0 &&
                    <>
                        <Slider {...settings}>

                            {achievement.map(a => {
                                return (
                                    <div className='d-flex justify-content-center'>
                                        <img src={a.image} alt="" />
                                    </div>
                                )
                            })}
                        </Slider>
                    </>
                }
            </div>
        </div>
    )
}
