import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';

export default function MediaCarousel() {



    const [mediaDesktop, setMediaDesktop] = useState([])
    const [mediaMobile, setMediaMobile] = useState([])

    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {

        axios.get('/news/get-all-record')
            .then(res => {
                console.log("res.data in news", res.data);
                const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
                console.log("data in mobileType", mobileImage);
                setMediaMobile(mobileImage)

                const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
                console.log("data in desktopType", desktopImage);
                setMediaDesktop(desktopImage)
            }).catch(err => {
                console.log(err);
            })

        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.addEventListener('resize', handleResize)
        }
    }, [])

    // let slides;



    // mediaDesktop.length < 3 ? slides = mediaDesktop.length : slides = 3

    // Slick carousel settings
    const settings = {
        // dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 4000, // Transition speed in milliseconds
        autoplaySpeed: 4000, // Interval between each slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div>
            <div className='media-carousel-main'>
                {mediaDesktop.length > 0 &&
                    <>
                        <Slider {...settings}>

                            {isMobileView ?

                                mediaMobile.map(a => {
                                    return (
                                        <div className='d-flex justify-content-center'>
                                            <img src={a.image} alt="" className='img-fluid' />
                                        </div>
                                    )
                                })
                                : mediaDesktop.map(a => {
                                    return (
                                        <div className='d-flex justify-content-center'>
                                            <img src={a.image} alt="" className='img-fluid' />
                                        </div>
                                    )
                                })}
                        </Slider>
                    </>
                }
            </div>
        </div>
    )
}
