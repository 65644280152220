import React, { useEffect, useRef, useState } from 'react'
import Header from '../Components/Header'
// import Footer from '../Components/Footer'
import '../Styles/Career.css'
import JobCarousel from './JobCarousel'
import { Button, Form, Modal } from "react-bootstrap";
import axios from 'axios'
// import Internship from '../Images/Internship Position.png'
// import DigitalMarketer from '../Images/Digital Marketer Position.png'
// import WebDesigner from '../Images/Web Designer Position.png'
// import WebDeveloper from '../Images/Web Developer Position.png'
// import UIUX from '../Images/UiUx Designer Position.png'
// import FullStackDeveloper from '../Images/Full Stack Developer Position.png'
import NewFooter from './NewFooter';
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";


export default function Career() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  // const [question, setQuestion] = useState("")
  const [mobile_no, setMobileNo] = useState("")
  const [cv, setCV] = useState("")
  // const [position, setPosition] = useState("")
  const [duration, setDuration] = useState("")
  const [cover_letter, setCoverLetter] = useState(null)
  // const [technology_choice, setTechnologyChoice] = useState(null)
  const [activeTab, setActiveTab] = useState('Development');
  const [show, setShow] = useState(false);
  const [showPosition, setShowPosition] = useState("")

  const [course, setCourse] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  // const [uniqueBranches, setUniqueBranches] = useState([])

  useEffect(() => {
    axios.get(`vacancy/get-all-record`)
      .then(res => {
        console.log("All vacancies", res.data.data)
        const filteredData = res.data.data.filter(b => b.branch === activeTab)
        console.log("filteredData in res", filteredData);
        setCourse(filteredData)
        // setUniqueBranches([...new Set(res.data.data.map(item => item.branch))])
      })
      .catch(err => console.log(err))
  }, [activeTab])


  const uniqueBranches = ["Development", "Marketing", "Operations", "Design", "Management"]

  // console.log("uniqueBranches", uniqueBranches); 


  // const handleQuestionSubmit = (e) => {
  //   e.preventDefault()
  //   const newData = { name, email, question }
  //   axios.post(`questions/add`, newData)
  //     .then(res => {
  //       console.log(res.data);
  //       toast.success("Question form submitted")
  //     }).catch(err => {
  //       console.log(err);
  //       toast.error("Form not submitted")
  //     })
  //   setEmail("")
  //   setQuestion("")
  //   setName("")
  // }

  // Perform email validation using a regular expression
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;
  const captchaRef = useRef(null);
  const onChange = (value) => {
    // This function will be called when the ReCAPTCHA is completed.
    setCaptchaVerified(true);
    console.log(value);
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      e.target.setCustomValidity('Email is incorrect.');
    } else {
      e.target.setCustomValidity('');
    }
  };


  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setMobileNo(truncatedValue);

    if (numericValue.length > 11) {
      e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
    } else {
      e.target.setCustomValidity('');
    }
  };

  // const alphabetOnlyRegex = /^[a-zA-Z]+$/;

const handleNameChange = (e) => {
  const inputValue = e.target.value;
  // Filter out non-alphabetic characters
  const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
  e.target.value = alphabeticValue;
  setName(alphabeticValue);
  
  // Check if the input contains only alphabets
  if (inputValue !== alphabeticValue) {
    e.target.setCustomValidity('Name should only contain alphabets.');
  } else {
    e.target.setCustomValidity('');
  }
};

const handleDurationChange = (e) => {
  const inputValue = e.target.value;
  // Filter out non-numeric characters
  const numericValue = inputValue.replace(/[^0-9]/g, '');
  e.target.value = numericValue;
  setDuration(numericValue);
  
  // Check if the input contains only numbers
  if (inputValue !== numericValue) {
    e.target.setCustomValidity('Please enter only numeric characters.');
  } else {
    e.target.setCustomValidity('');
  }
};


  // const handleFileChange = (event, setter) => {
  //   event.preventDefault()
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const base64Data = e.target.result;
  //       console.log('Base64:', base64Data); // Add this console log
  //       setter(base64Data);
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   }
  // };


  const handleFileChange = (event, setter) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type !== 'application/pdf' &&
        selectedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        event.target.setCustomValidity('File format is not supported. Please upload a PDF or DOCX file.');
        event.target.reportValidity();
      } else if (selectedFile.size > 1048576) {
        event.target.setCustomValidity('File size exceeds the maximum allowed size (1MB).');
        event.target.reportValidity();
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          console.log('Base64:', base64Data); // Add this console log
          setter(base64Data);
          event.target.setCustomValidity('');
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };



  const SubmitData = (e) => {
    if (!isCaptchaVerified) {
      setSubmitMessage("Please complete the ReCAPTCHA before submitting.");
      return;
    }

    setIsSubmitting(true);

    setTimeout(() => {
      // Reset the form data, ReCAPTCHA, and submit message.
      setCaptchaVerified(false);
      setSubmitMessage("");
      setIsSubmitting(false);
      // Reset the ReCAPTCHA component using the ref.
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }, 2000);
    e.preventDefault();
    let newData = {
      name,
      mobile_no,
      email,
      cv,
      cover_letter,
      technology_choice: showPosition,
      position: showPosition,
      duration
    };
    console.log("newData", newData);
    axios
      .post(`/career_enquirires/add`, newData)
      .then((resp) => {
        console.log("resp", resp.data);
        toast.success("Career Enquiry added successfully")
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Career Enquiry rejected")
      });
    setName("");
    setEmail("");
    setMobileNo("");
    setDuration("")
    setCV("")
    setCoverLetter("")
  };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow1 = () => {
    setShow(true);
  }

  console.log("showPosition", showPosition);


  // const avaialablePositions = [Internship, DigitalMarketer, WebDesigner, WebDeveloper, UIUX, FullStackDeveloper, Internship, WebDesigner]

  // Perform email validation using a regular expression
  // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;

  // const handleEmailChange = (e) => {
  //     setEmail(e.target.value);
  //     if (!emailRegex.test(e.target.value)) {
  //         e.target.setCustomValidity('Email is incorrect.');
  //     } else {
  //         e.target.setCustomValidity('');
  //     }
  // };

  return (
    <>
      <Header />
      <div className='career-main'>

        {/* <section className='description'>
          <div className='career-internship'>
            <h1>Career and Internship</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy  text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy  text of the printing and typesetting industry.</p>
          </div>
          <div className='career-enquiry'>
            <form className='form-data' onSubmit={handleQuestionSubmit}>
              <h1>ASK YOUR QUESTIONS</h1>
              <input type="text" name="Name" className="form-data-name"
                placeholder='Name'
                onChange={(e) => setName(e.target.value)} value={name} 
                required/>
              <input type="text" name="Email" className="form-data-email"
                placeholder='Email'
                onChange={handleEmailChange} value={email} 
                required/>
              <textarea name="" id="" rows={5}
                placeholder='Ask a question'
                className='form-data-message'
                onChange={(e) => setQuestion(e.target.value)} value={question} 
                required/>
              <button type="submit" className='btn btn-danger w-100 mt-3 form-data-button'>Send</button>
            </form>
          </div>
        </section> */}

        <section className='job-opening-section'>
          <div className='career-heading'>
            <h1>Job Opening</h1>
          </div>

          <div className='career-filtering'>
            <nav className="nav nav-pills nav-fill">
              {uniqueBranches.map((branch, index) => {
                return (
                  <button
                    className={`nav-link ${activeTab === branch ? 'active' : ''} ${branch.toLowerCase()}`}
                    onClick={() => handleTabClick(branch)}
                  >
                    {branch}
                  </button>
                )
              })}

              {/* <button
                className={`nav-link ${activeTab === 'Marketing' ? 'active' : ''} marketing`}
                onClick={() => handleTabClick('Marketing')}
              >
                Marketing
              </button>
              <button
                className={`nav-link ${activeTab === 'Designer' ? 'active' : ''} designer`}
                onClick={() => handleTabClick('Designer')}
              >
                Designer
              </button>
              <button
                className={`nav-link ${activeTab === 'Management' ? 'active' : ''} management`}
                onClick={() => handleTabClick('Management')}
              >
                Management
              </button>
              <button
                className={`nav-link ${activeTab === 'Operations' ? 'active' : ''} operations`}
                onClick={() => handleTabClick('Operations')}
              >
                Operations
              </button> */}
            </nav>

            <div
            // id="tabContent"
            >
              {/* {activeTab === 'Developer' && <DeveloperTab />}
              {activeTab === 'Marketing' && <MarketingTab />}
              {activeTab === 'Designer' && <DesignerTab />} */}

              {/* {activeTab === 'Developer' && <JobCarousel />}
              {activeTab === 'Marketing' && <JobCarousel />}
              {activeTab === 'Designer' && <JobCarousel />} */}

              {activeTab &&
                <JobCarousel activeTab={activeTab} handleShow1={handleShow1}
                  setShowPosition={setShowPosition} course={course} />
              }

            </div>
          </div>
        </section>

        {/* <div className='available-positions'>
        <h1 className='text-center'>Positions</h1>
          <div className='row'>
            {avaialablePositions.map(positions => {
              return (
                <div className='col-6 col-sm-4 col-md-3 d-flex justify-content-center'>
                  <img src={positions} alt="" className='position' />
                </div>
              )
            })}
          </div>
        </div> */}

      </div>

      <div className='career-modal'>
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={SubmitData}>
            <Modal.Header closeButton>
              <Modal.Title className="text-dark career-text-dark fw-bold">Career Enquiry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="mb-3 ">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark career-text-dark fw-500"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control career-form-control"
                  placeholder="enter your name here"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>

              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark career-text-dark fw-500"
                >
                  Mobile No.
                </label>
                <input
                  type="number"
                  className="form-control career-form-control"
                  id="exampleFormControlInput1"
                  placeholder="ex. 90xxxxxx"
                  value={mobile_no}
                  onChange={handleMobileChange}
                  required
                />
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark career-text-dark fw-500"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control career-form-control"
                  placeholder="test@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              {/* <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark career-text-dark fw-500"
                >
                  Position
                </label>
                <input
                  type="text"
                  className="form-control career-form-controltext-dark career-text-dark fw-500"
                  id="exampleFormControlInput1"
                  disabled
                  value={position}
                  onChange={() => setPosition(showPosition)}
                />
              </div> */}
              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark career-text-dark fw-500"
                >
                  Duration
                </label>
                <input
                  class="form-control career-form-controltext-dark career-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  placeholder="duration"
                  type='text'
                  value={duration}
                  onChange={handleDurationChange}
                  required
                ></input>
              </div>

              {/* <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark career-text-dark fw-500"
                >
                  Technology
                </label>
                <input
                  class="form-control career-form-controltext-dark career-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  placeholder="technology"
                  type='text'
                  value={technology_choice}
                  onChange={(e) => setTechnologyChoice(e.target.value)}
                ></input>
              </div> */}

              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark career-text-dark fw-500"
                >
                  Upload CV
                </label>
                <input
                  class="form-control career-form-controltext-dark career-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  type='file'
                  onChange={(e) => {
                    // console.log('cv', e.target.files[0].name)
                    handleFileChange(e, setCV)
                  }}
                  accept=".docx,.pdf"
                ></input>
              </div>

              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark career-text-dark fw-500"
                >
                  Upload Cover Letter
                </label>
                <input
                  class="form-control career-form-controltext-dark career-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  type='file'
                  onChange={(e) => {
                    // console.log('cover_letter', e.target.files[0].name)
                    handleFileChange(e, setCoverLetter)
                  }}
                  accept=".docx,.pdf"
                  required
                ></input>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6Lc-cwEpAAAAAIln-wa3CB5qrsM7DGh7FG6icrYj"
                  onChange={onChange} className='mt-4'
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                {isSubmitting ? "Submitted" : "Submit"}
              </Button>
              {submitMessage && <p>{submitMessage}</p>}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>

      <div className='mt-5'>
        {/* <Footer /> */}
        <NewFooter />
      </div>
    </>
  )
}
