import React from "react"
import "react-multi-carousel/lib/styles.css";
import Slider from 'react-slick';
// import ServiceImage1 from '../Images/HomeImagesNew/WEBSITE development nn.png'
// import ServiceImage2 from '../Images/HomeImagesNew/Social Media.png'
import WebsiteDevelopment from '../Images/HOME SERVICES IMAGES/WEBSITE DEVELOPMENT.png'
import SocialMedia from '../Images/HOME SERVICES IMAGES/SOCIAL MEDIA.png'
import DataAnalytics from '../Images/HOME SERVICES IMAGES/4 DATA ANALYTICS.png'
import AI from '../Images/HOME SERVICES IMAGES/5 AL.png'
import AppDevelopment from '../Images/HOME SERVICES IMAGES/APP DEVELOPMENT N.png'
import OutSourcing from '../Images/HOME SERVICES IMAGES/OUTSOURCING.png'
import ProjectManagement from '../Images/HOME SERVICES IMAGES/PROJECT MANAGEMENT.png'
import ResourceAugmentation from '../Images/HOME SERVICES IMAGES/RESOURCE AUGMENTATION.png'
// import ServiceImage1 from '../Images/ServiceImage1.png'
// import ServiceImage2 from '../Images/socilamediaforHome.png'
import '../Styles/ServiceCarousel.css'


function ServiceCarousel() {

    const serviceImages = [WebsiteDevelopment, SocialMedia, DataAnalytics, AI, AppDevelopment, ProjectManagement, OutSourcing ,ResourceAugmentation]

    // Slick carousel settings
    const settings = {
        // dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 3000, // Transition speed in milliseconds
        autoplaySpeed: 3000, // Interval between each slide change
    };

    return (
        <div>
            <div className='home-service-carousel'>
                <div className='d-flex justify-content-center heading'>
                    <h1>Our Services</h1>
                </div>
                <Slider {...settings}>

                    {serviceImages.map(service => {
                        return (
                            <>
                                <div className='d-flex justify-content-center'>
                                    <img src={service} alt="" className="img-fluid" />
                                </div>
                            </>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default ServiceCarousel