import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from "react-bootstrap";
import CountUp from "react-countup";
import ReCAPTCHA from "react-google-recaptcha";

// import homeImage1 from '../Images/HomePageImg-1.png'
import manImage2 from '../Images/Animatedman-image2.png'
// import manImage3 from '../Images/Last banner1 iconVV.png'
// import manImage1 from '../Images/man-image1.png'
// import serviceImage from '../Images/ServiceImage.png'
// import manWorkingImg from '../Images/manWorkingImg.png'
import '../Styles/Home.css'
import TechCarousel from './TechCarousel'
import Header from '../Components/Header'
// import Footer from '../Components/Footer'
import TaskCounter from '../Images/CounterTask.png'
import MedalCounter from '../Images/CounterMedal.png'
import CupCounter from '../Images/CounterCoffee.png'
import ClientCounter from '../Images/CounterClients.png'
import HomeContactSectionLogo from '../Images/HomeContactSectionLogo.png'
import axios from 'axios'
import NewFooter from './NewFooter';
import ServiceCarousel from './ServiceCarousel';
import AboutUsImage from '../Images/HomeImagesNew/AboutUs.png'
import WhoWeAreImage from '../Images/HomeImagesNew/WhoWeAre.png'
// import NewHomeBanner from '../Images/HomeImagesNew/BannerImage1.png'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export default function Home() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  // const [topic, setTopic] = useState("")
  const [mobile, setMobile] = useState("")
  const [service, setService] = useState("");
  const [address, setAddress] = useState("")
  const [comment, setComment] = useState("");
  const [count, setCount] = useState([])
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const captchaRef = useRef(null);
  const navigate = useNavigate()
  const onChange = (value) => {
    // This function will be called when the ReCAPTCHA is completed.
    setCaptchaVerified(true);
    console.log(value);
  }
  // const handleFormSubmit = (e) => {
  //   e.preventDefault()
  //   const newData = { name, mobile_no: mobile, email, topic }
  //   axios.post(`freeConsultaion/add`, newData)
  //     .then(res => {
  //       console.log(res.data);
  //       toast.success("Consultation added successfully")
  //     }).catch(err => {
  //       console.log(err);
  //       toast.error("Something went wrong")
  //     })
  //   setName("")
  //   setTopic("")
  //   setMobile("")
  //   setEmail("")
  // }

  const SubmitData = (e) => {
    e.preventDefault();
    if (!isCaptchaVerified) {
      setSubmitMessage("Please complete the ReCAPTCHA before submitting.");
      return;
    }

    setIsSubmitting(true);

    setTimeout(() => {
      // Reset the form data, ReCAPTCHA, and submit message.
      setCaptchaVerified(false);
      setSubmitMessage("");
      setIsSubmitting(false);
      // Reset the ReCAPTCHA component using the ref.
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }, 2000);
    let newData = {
      name: name,
      mobile_no: mobile,
      email: email,
      service: service,
      address: address,
      comment: comment,
    };
    console.log("newData", newData);
    axios
      .post(`getAquote/add`, newData)
      .then((resp) => {
        console.log("resp", resp.data);
        toast.success("Quote sent successfully")
        handleClose()
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Quote form rejected")
      });
    setName("");
    setEmail("");
    setMobile("");
    setService("");
    setAddress("")
    setComment("");



  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    axios.get(`count/get-all-record`)
      .then(res => {
        console.log("All Counts", res.data.data);
        setCount(res.data.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  // Perform email validation using a regular expression
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      e.target.setCustomValidity('Email is incorrect.');
    } else {
      e.target.setCustomValidity('');
    }
  };


  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setMobile(truncatedValue);

    if (numericValue.length > 11) {
      e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setName(alphabeticValue);

    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleServiceChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setService(alphabeticValue);

    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setComment(alphabeticValue);

    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const [serviceDesktop, setServiceDesktop] = useState([])
  const [serviceMobile, setServiceMobile] = useState([])

  useEffect(() => {
    axios.get('/slider/get_all_records')
      .then(res => {
        console.log("res.data in services", res.data);
        const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
        console.log("data in mobileType", mobileImage);
        setServiceMobile(mobileImage)

        const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
        console.log("data in desktopType", desktopImage);
        setServiceDesktop(desktopImage)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  // const headerCarouselImages = [NewHomeBanner, AboutUsImage, WhoWeAreImage]


  return (
    <>
      <div className='Home-main'>
        <Header />
        <section className='Home-main-section1'>
          <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel"
            // style={{ zIndex: 2 }}
          >
            <div className="carousel-inner">
              {serviceDesktop.length > 0 &&
                <>
                  {isMobileView ? serviceMobile.map((service, index) => {
                    return (
                      <div
                        className={`carousel-item ${(index === 0) ? 'active' : ''}`}
                        data-bs-interval="3000"
                      >
                        {/* <img src={homeImage1} alt="" srcset="" className='homeImage1 img-fluid' /> */}
                        <img src={service.image} alt="" srcset="" className='homeImage1 img-fluid' />
                      </div>
                    )
                  }) : serviceDesktop.map((service, index) => {
                    return (
                      <div
                        className={`carousel-item ${(index === 0) ? 'active' : ''}`}
                        data-bs-interval="3000"
                      >
                        {/* <img src={homeImage1} alt="" srcset="" className='homeImage1 img-fluid' /> */}
                        <img src={service.image} alt="" srcset="" className='homeImage1 img-fluid' />
                      </div>
                    )
                  })
                  }
                </>
              }

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          {/* <div className='gitform'>
            <form className='form-data' onSubmit={handleFormSubmit}>
              <h1>GET IN TOUCH</h1>
              <input type="text" name="Name" className="form-data-name"
                placeholder='Name'
                onChange={(e) => setName(e.target.value)} value={name} 
                required/>
              <input type="text" name="Email" className="form-data-email"
                placeholder='Email'
                onChange={handleEmailChange} value={email} 
                required/>
              <input type="text" name="Mobile" className="form-data-email"
                placeholder='Mobile No'
                onChange={handleMobileChange} value={mobile} 
                required/>
              <textarea name="" id="" rows={2}
                placeholder='Topic'
                className='form-data-message'
                onChange={(e) => setTopic(e.target.value)} value={topic} 
                required/>
              <button type="submit" className='btn btn-danger w-100 mt-3 form-data-button'>Send</button>
            </form>
          </div> */}

          {/* <div className='section1-motto'>
            <p className='ms-4 mt-2'>Empowering Innovation, Transforming Technology</p>
          </div>

          <div className='section2-motto'>
            <p className='ms-4 mt-2'>Empowering Technology</p>
          </div> */}

        </section>

        <section className='Home-main-section2'>
          <div className='row about-us-part'>
            <div className='col-12 col-md-7 col-lg-7 col-xl-6 div-1'>
              <div>
                <h1>About us</h1>
                {/* <p>Our unwavering focus is on democratizing education through affordable pricing and transparent policies. This inclusive approach empowers a diverse group and fosters their success in professional endeavors. Scope's mission is to reshape learning, particularly for technology enthusiasts. Our meticulously curated, hands-on curriculum aims to deliver an unmatched educational journey that goes beyond conventional limits.
                </p> */}

                <p>
                  Sumago Infotech aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism. Sumago Infotech has industry-specific software expertise in Technology, Financial, Healthcare, Media, Manufacturing, and many other sectors. The company specializes in offering Web Designing, Web Application Development, Mobile Application Development, Software Development, Digital Marketing, Software Testing, Quality Assurance services, and many more.
                </p>
                <button className='btn mt-5'
                  onClick={() => {
                    navigate('/about')
                    scrollToTop()
                  }}>MORE INFO</button>
              </div>
            </div>
            <div className='d-none d-md-block col-md-5 col-lg-5 col-xl-6 div-2'>
              {/* <img src={manImage1} alt="" className='' /> */}
              <img src={AboutUsImage} alt="" className='img-fluid' />
              <h1 className='about-us-name'>MRS. SONALI GORADE</h1>
              <h1 className='about-us-designation'>CEO</h1>
            </div>
          </div>
        </section>

        <section className='Home-main-section3'>

          {/* <div className='service-img-part'>
            <img src={serviceImage} alt="" className='homeImage3' />
          </div> */}

          <div className='get-a-quote'>
            <div className='row gaq-row'>
              <div className='col-4 d-md-block d-none'>
                <img src={manImage2} alt="" className='img-fluid' />
              </div>
              <div className='col-md-4 col-6 gaq-heading'>
                {/* <h1>Sumago Infotech provides the smart solution for your business.</h1> */}
                <h1>Sumago Infotech is your Smart Solution Partner for all your business needs!</h1>
              </div>
              <div className='col-md-4 col-6 gaq-button d-flex align-items-center'>
                <button type="submit" className='btn shadow p-3 bg-body-tertiary rounded button1'
                  onClick={handleShow}>GET A QUOTE</button>
              </div>
            </div>
          </div>

          {/* <div className='service-img-part'>
            <img src={serviceImage} alt="" className='homeImage3' />
          </div> */}

          <div className='service-img-part'>
            <ServiceCarousel />
          </div>

          {/* <div className='service-heading'> */}
          {/* <h1>Our service</h1>
            <div id="carouselExampleAutoplaying" className="carousel slide service-carousel" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={socialMediaImage} className="d-block w-100 socialMediaImage" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={manImage1} className="d-block w-100 socialMediaImage" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={manImage1} className="d-block w-100 socialMediaImage" alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev caro3-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon caro3-next" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='blank-grey-div'></div> */}
        </section>

        {/* <section className='Home-main-section4'>
          <div className='we-are-experts'>
            <h1>We Are Experts, In Our Work!</h1>
            <p>Lorem Ipsum is simply dummy text of the printing
              and typesetting industry. Lorem Ipsum is simply dummy
              text of the printing and typesetting industry.
              Lorem Ipsum is simply dummy text of the printing
              and typesetting industry. Lorem Ipsum is simply dummy
              text of the printing and typesetting industry.</p>
          </div>
        </section> */}

        <section className='contactUs-banner-section'>
          <div className='banner-desc d-flex align-items-center'>
            <div className='ms-5'>
              <h1>Contact us</h1>
              {/* <h2 className='mt-5'>We are a team of Committed innovative . </h2> */}
              <h2 className='mt-5'>We are a team of innovative solution-oriented members <br /> available for your IT needs.
                Feel Free to Call us!</h2>
              {/* <h2>Feel Free to call .</h2> */}
              <div>
                <a href="tel:+91 8530648814">
                  <button className='btn btn-light call-button'>
                    CALL US
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className='banner-image d-sm-block d-none'>
            <img src={HomeContactSectionLogo} alt="" className='img-fluid' />
          </div>
          {/* <div className='banner-image'>
            <img src={HomeContactSectionLogo} alt="" className='img-fluid' />
          </div> */}
        </section>

        <section className='Home-main-section5'>
          <h1 className='technology'>Technology</h1>
          <div className='carousel-section'>
            <TechCarousel />
          </div>
          <div className='who-are-we row gaq-button'>
            <div className='col-sm-6 col-12'>
              <h1>Who We Are?</h1>
              <p>We are a team of committed innovative, client-sensitive and experienced software professionals who always strive to deliver customized, cost- effective and long-term software solutions that complement our client's objective and result in a satisfied customer.</p>
            </div>
            <div className='col-6 d-none d-md-grid d-flex justify-content-center'>
              {/* <img src={manWorkingImg} alt="" srcset="" className='ms-5' /> */}
              <img src={WhoWeAreImage} alt="" srcset="" className='img-fluid' />
            </div>
          </div>
          <div className='who-are-we-1 gaq-button'>
            <button type="submit" className='btn shadow p-3 bg-body-tertiary rounded button2'
              onClick={handleShow}>GET A QUOTE</button>
          </div>
          <div className='d-flex justify-content-evenly counter-main mt-5'>
            <div>
              <div className='d-flex justify-content-center'>
                <img className='img-fluid' src={TaskCounter} alt="" />
              </div>
              <h1>
                <CountUp duration={5} end={count[0]?.projects} />
              </h1>
              <p>Projects Done</p>
            </div>
            <div>
              <div className='d-flex justify-content-center'>
                <img className='img-fluid' src={ClientCounter} alt="" />
              </div>
              <h1>
                <CountUp duration={5} end={count[0]?.clients} />
              </h1>
              <p>Satisfied Clients</p>
            </div>
            <div>
              <div className='d-flex justify-content-center'>
                <img className='img-fluid' src={CupCounter} alt="" />
              </div>
              <h1>
                <CountUp duration={5} end={count[0]?.cup_of_coffee} />
              </h1>
              <p>Cup of Coffee</p>
            </div>
            <div>
              <div className='d-flex justify-content-center'>
                <img className='img-fluid' src={MedalCounter} alt="" />
              </div>
              <h1>
                <CountUp duration={5} end={count[0]?.awards} />
              </h1>
              <p>Awards Win</p>
            </div>
          </div>
        </section>
        <div className='gaq-modal'>
          <Modal show={show} onHide={handleClose}>
            <Form onSubmit={SubmitData}>
              <Modal.Header closeButton>
                <Modal.Title className="text-dark gaq-text-dark fw-bold">Get Quote</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="mb-3 ">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-dark gaq-text-dark fw-500"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control gaq-form-control"
                    placeholder="enter your name here"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />
                </div>

                <div class="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-dark gaq-text-dark fw-500"
                  >
                    Mobile No.
                  </label>
                  <input
                    type="text"
                    className="form-control gaq-form-control "
                    id="exampleFormControlInput1"
                    placeholder="ex. 90xxxxxx"
                    value={mobile}
                    onChange={handleMobileChange}
                    required
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-dark gaq-text-dark fw-500"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control gaq-form-control "
                    placeholder="test@gmail.com"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-dark gaq-text-dark fw-500"
                  >
                    Type Of Service
                  </label>
                  <input
                    type="text"
                    className="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                    id="exampleFormControlInput1"
                    placeholder="services"
                    value={service}
                    onChange={handleServiceChange}
                    required
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleFormControlTextarea1"
                    class="form-label text-dark gaq-text-dark fw-500"
                  >
                    Address
                  </label>
                  <textarea
                    class="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                    id="exampleFormControlTextarea1"
                    placeholder="address"
                    rows="1"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  ></textarea>
                </div>

                <div class="mb-3">
                  <label
                    for="exampleFormControlTextarea1"
                    class="form-label text-dark gaq-text-dark fw-500"
                  >
                    Comment
                  </label>
                  <textarea
                    class="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                    id="exampleFormControlTextarea1"
                    placeholder="any comment"
                    rows="1"
                    value={comment}
                    onChange={handleCommentChange}
                    required
                  ></textarea>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6Lc-cwEpAAAAAIln-wa3CB5qrsM7DGh7FG6icrYj"
                    onChange={onChange} className='mt-4'
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit"
                >
                  {isSubmitting ? "Submitted" : "Submit"}
                </Button>
                {submitMessage && <p>{submitMessage}</p>}
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
        {/* <Footer /> */}
        <NewFooter />
      </div>
    </>
  )
}
