import React from 'react'
import '../Styles/Header.css'
// import company_logo from '../Images/SUMAGO Logo header.png'
import NewSumagoLogo from '../Images/NewSumagoLogo.png'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

export default function Header() {

  const navigate = useNavigate()

  return (
    <>
      <div className='navbar-flex' id='navbar-flex-id'>
        <div className='header-strip company_logo d-flex justify-content-center'>

          {/* <img src={company_logo} alt="" srcset="" /> */}

          <div className='new-logo d-flex justify-content-center'>
            <img src={NewSumagoLogo} alt="" className='img-fluid' style={{cursor:"pointer"}}
            onClick={() => {
              navigate('/home')
            }}/>
          </div>

        </div>
        <Navbar/>
        
      </div>
    </>
  )
}
