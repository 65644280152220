import React from 'react'
import Header from '../Components/Header'
// import Footer from '../Components/Footer'
import '../Styles/About.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import card1Img from "../Images/Group 3462.png"
import card2Img from "../Images/Group 3461.png"
import card3Img from "../Images/Group 3460.png"
import aboutVerticalBanner from "../Images/ABOUT US IMAGE.png"
// import Vision from '../Images/Our Vision.png'
// import Mission from '../Images/Our Mission.png'
import AchievementCarousel from './AchievementCarousel'
import MediaCarousel from './MediaCarousel'
import NewFooter from './NewFooter'
import { Card, Col, Row } from 'react-bootstrap'

export default function About() {

  const [managementTeam, setManagementTeam] = useState([])
  const [developmentTeam, setDevelopmentTeam] = useState([])
  const [trainingTeam, setTrainingTeam] = useState([])
  const [designTeam, setDesignTeam] = useState([])

  const [chunkSize, setChunkSize] = useState(4)

  useEffect(() => {
    axios.get(`admin_team/get-all-record`)
      .then(res => {
        console.log("Management Team", res.data);
        setManagementTeam(res.data)
      }).catch(err => {
        console.log(err);
      })

    axios.get(`trainee_team/get-all-record`)
      .then(res => {
        console.log("Training Team", res.data);
        setTrainingTeam(res.data)
      }).catch(err => {
        console.log(err);
      })

    axios.get(`developement_team/get-all-record`)
      .then(res => {
        console.log("Development Team", res.data);
        setDevelopmentTeam(res.data)
      }).catch(err => {
        console.log(err);
      })

    axios.get(`design_team/get-all-record`)
      .then(res => {
        console.log("Design Team", res.data);
        setDesignTeam(res.data)
      }).catch(err => {
        console.log(err);
      })

    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setChunkSize(2)
      } else if (window.innerWidth <= 768) {
        setChunkSize(3)
      } else {
        setChunkSize(4)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  const chunkedData1 = [];
  const chunkedData2 = [];
  const chunkedData3 = [];
  const chunkedData4 = [];
  // const chunkSize = 4;

  // Divide the dataArray into chunks of size 4
  for (let i = 0; i < managementTeam.length; i += chunkSize) {
    chunkedData1.push(managementTeam.slice(i, i + chunkSize));
  }

  for (let i = 0; i < trainingTeam.length; i += chunkSize) {
    chunkedData2.push(trainingTeam.slice(i, i + chunkSize));
  }

  for (let i = 0; i < developmentTeam.length; i += chunkSize) {
    chunkedData3.push(developmentTeam.slice(i, i + chunkSize));
  }

  for (let i = 0; i < designTeam.length; i += chunkSize) {
    chunkedData4.push(designTeam.slice(i, i + chunkSize));
  }

  console.log("chunkedData1", chunkedData1);
  console.log("chunkedData2", chunkedData2);
  console.log("chunkedData3", chunkedData3);
  console.log("chunkedData4", chunkedData4);

  return (
    <>
      <Header />
      <div className='about-us-main'>
        {/* <div className='our-description'>
          <h1>About us</h1>
        </div> */}

        {/* <Row>
          <div className=' col-6'>
            <Card className='m-0 p-0 mt-5'>
              <img src={aboutVerticalBanner} alt="aboutVerticalBanner" className='abcimg d-lg-block d-md-block d-none d-sm-none' />
            </Card>
          </div>
          <div className=' col-6 aboutsubMainCard3'>
            <Card className='aboutsubMainCard border-0 my-5' >
              <Row>
                <Col lg={2}>
                  <div className='circleShape'>
                    <img src={card1Img} style={{ width: "150%" }} alt="" />
                  </div>
                </Col>
                <Col lg={10} className='py-5 px-5 text-white'>
                  <h1>
                    ABOUT US
                  </h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                    Sumago Infotech aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS”
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className='aboutsubMainCard1 border-0 my-5' >
              <Row>
                <Col lg={2}>
                  <div className='circleShape'>
                    <img src={card2Img} style={{ width: "150%" }} alt="" />
                  </div>
                </Col>
                <Col lg={10} className='py-5 px-5 text-white'>
                  <h1>
                    VISION
                  </h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                    We aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className='aboutsubMainCard2 border-0 my-5' >
              <Row>
                <Col lg={2}>
                  <div className='circleShape'>
                    <img src={card3Img} style={{ width: "150%" }} alt="" />
                  </div>
                </Col>
                <Col lg={10} className='py-5 px-5 text-white'>
                  <h1>
                    MISSION
                  </h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                    At Sumago Infotech, We “Strive with Technology” to provide the most effective and affordable service that fulfills our customer’s needs and budget. We provide customized websites and software solutions that suit customer’s company objective
                  </p>
                </Col>
              </Row>
            </Card>
          </div>

        </Row> */}
        <Row>
          <div className='col-lg-6 col-md-12'> {/* Change col-lg-6 to col-md-12 */}
            <Card className='m-0 p-0'>
              <img src={aboutVerticalBanner} alt="aboutVerticalBanner" className='abcimg d-none d-lg-block' />
            </Card>
          </div>
          <div className='col-lg-6 col-md-12 aboutsubMainCard3'> {/* Change col-lg-6 to col-md-12 */}
            <Card className='aboutsubMainCard border-0'>
              <Row>
                <Col lg={2} md={3}> {/* Adjust column sizes for medium screens */}
                  <div className='circleShape'>
                    <img src={card1Img} alt='' className='' />
                  </div>
                </Col>
                <Col lg={10} md={9} className='py-5 px-5 text-white'> {/* Adjust column sizes for medium screens */}
                  <h1>ABOUT US</h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                  Sumago Infotech aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism. Sumago Infotech has industry-specific software expertise in Technology, Financial, Healthcare, Media, Manufacturing, and many other sectors. The company specializes in offering Web Designing, Web Application Development, Mobile Application Development, Software Development, Digital Marketing, Software Testing, Quality Assurance services, and many more.
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className='aboutsubMainCard1 border-0 my-5'>
              <Row>
                <Col lg={2} md={3}> {/* Adjust column sizes for medium screens */}
                  <div className='circleShape'>
                    <img src={card2Img} alt="img-fluid" />
                  </div>
                </Col>
                <Col lg={10} md={9} className='py-5 px-5 text-white'> {/* Adjust column sizes for medium screens */}
                  <h1>VISION</h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                  We aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS”. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism.
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className='aboutsubMainCard2 border-0 my-5'>
              <Row>
                <Col lg={2} md={3}> {/* Adjust column sizes for medium screens */}
                  <div className='circleShape'>
                    <img src={card3Img} alt="img-fluid" />
                  </div>
                </Col>
                <Col lg={10} md={9} className='py-5 px-5 text-white'> {/* Adjust column sizes for medium screens */}
                  <h1>MISSION</h1>
                  <p style={{ fontSize: "medium", textAlign: "justify" }}>
                  At Sumago Infotech, We “Strive with Technology” to provide the most effective and affordable service that fulfills our customer’s needs and budget. We provide customized websites and software solutions that suit customer’s company objectives. We always keep involving our customers in an entire process starting from design through deployment, so that your ideas can be incorporated into our work.
                  </p>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>



        {/* <div className='section-vision'>
          <h1 className='philosophy-heading'>Our Philosophy</h1>
          <div className='d-flex justify-content-center philosophy-content'>
            <div>
              <p>Sumago Infotech aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS”. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism. </p>
            </div>
          </div>
          <div className='vision'>
            <div className='vision-content'>
              <div className='background-color-div'></div>
              <div className='outer-circle'>
                <div className='inner-circle'>
                  <img src={Vision} alt="" className='img-fluid' />
                </div>
              </div>
              <div className='actual-vision-content'>
                <p>We aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS”. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism.</p>
              </div>
            </div>


            <div className='actual-mission-content'>
              <p>We aspire to be the global sourcing choice of the world market and revolutionizes the way service processes function. To reach out to the common people across the globe and making Information Technology a tool for the “MASS” along with the tool for the “CLASS”. Creating innovative IT solutions and provide IT-enabled services to delight customers worldwide and build Relationships based on Trust, Values and Professionalism.</p>
            </div>
            <div className='mission-content'>
              <div className='outer-circle'>
                <div className='inner-circle'>
                  <img src={Mission} alt="" className='img-fluid' />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='team'>
          <h1 className='main-heading'>Team</h1>

          {/* <div className='management-team'>
            <h1>MANAGEMENT TEAM</h1>
            <div className='team-cards'>
              {managementTeam.map(management => {
                return (
                  <div className='main-div'>
                    <div className=''>
                      <img src={management.photo} alt="" className='img-fluid' />
                      <p className='fname'>{management.name}</p>
                      <p className='designation'>{management.designation_id}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}


          <div className='management-team'>
            <h1>MANAGEMENT TEAM</h1>
            {/* <div className='team-cards'> */}
            {chunkedData1.length > 0 &&
              <>
                {chunkedData1.map((row, rowIndex) => {
                  return (
                    <div className={`team-cards ${(rowIndex % 2 === 0 ? 'row-1' : 'row-2')}`}>
                      {row.map((management, colIndex) => {
                        return (
                          <div className={`main-div column-${colIndex}`}>
                            <div>
                              <img src={management.photo} alt="" className='img-fluid' />
                              <p className='fname'>{management.name}</p>
                              <p className='designation'>{management.designation_id}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </>
            }
            {/* </div> */}
          </div>


          <div className='design-team'>
            <h1>DIGITAL MARKETING & DESIGNING TEAM</h1>
            {/* <div className='team-cards'> */}
            {chunkedData4.length > 0 &&
              <>
                {chunkedData4.map((row, rowIndex) => {
                  return (
                    <div className={`team-cards ${(rowIndex % 2 === 0 ? 'row-1' : 'row-2')}`}>
                      {row.map((design, colIndex) => {
                        return (
                          <div className={`main-div column-${colIndex}`}>
                            <div>
                              <img src={design.photo} alt="" className='img-fluid' />
                              <p className='fname'>{design.name}</p>
                              <p className='designation'>{design.designation_id}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </>
            }
            {/* </div> */}
          </div>


          {/* <div className='training-team'>
            <h1>TRAINING TEAM</h1>
            <div className='d-flex justify-content-center'>
              {trainingTeam.filter(obj => obj.designation_id === "L&D Head").map(head => {
                return (
                  <div className='main-head'>
                    <div>
                      <img src={head.photo} alt="" className='' />
                      <p className='fname'>{head.name}</p>
                      <p className='designation'>{head.designation_id}</p>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='team-cards'>
              {trainingTeam.map(trainer => {
                return (
                  <div className='main-div'>
                    <div>
                      <img src={trainer.photo} alt="" className='' />
                      <p className='fname'>{trainer.name}</p>
                      <p className='designation'>{trainer.designation_id}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}


          <div className='training-team'>
            <h1>TRAINING TEAM</h1>
            {/* <div className='team-cards'> */}
            {chunkedData2.length > 0 &&
              <>
                {chunkedData2.map((row, rowIndex) => {
                  return (
                    <div className={`team-cards ${(rowIndex % 2 === 0 ? 'row-1' : 'row-2')}`}>
                      {row.map((training, colIndex) => {
                        return (
                          <div className={`main-div column-${colIndex}`}>
                            <div>
                              <img src={training.photo} alt="" className='img-fluid' />
                              <p className='fname'>{training.name}</p>
                              <p className='designation'>{training.designation_id}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </>
            }
            {/* </div> */}
          </div>


          {/* <div className='development-team'>
            <h1>DEVELOPMENT TEAM</h1>
            <div className='d-flex justify-content-center'>
              {developmentTeam.filter(obj => obj.designation_id === "CTO").map(head => {
                return (
                  <div className='main-head'>
                    <div>
                      <img src={head.photo} alt="" className='' />
                      <p className='fname'>{head.name}</p>
                      <p className='designation'>{head.designation_id}</p>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='team-cards'>
              {developmentTeam.map(developer => {
                return (
                  <div className='main-div'>
                    <div>
                      <img src={developer.photo} alt="" className='' />
                      <p className='fname'>{developer.name}</p>
                      <p className='designation'>{developer.designation_id}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}


          <div className='development-team'>
            <h1>DEVELOPMENT TEAM</h1>
            {/* <div className='team-cards'> */}
            {chunkedData3.length > 0 &&
              <>
                {chunkedData3.map((row, rowIndex) => {
                  return (
                    <div className={`team-cards ${(rowIndex % 2 === 0 ? 'row-1' : 'row-2')}`}>
                      {row.map((development, colIndex) => {
                        return (
                          <div className={`main-div column-${colIndex}`}>
                            <div>
                              <img src={development.photo} alt="" className='img-fluid' />
                              <p className='fname'>{development.name}</p>
                              <p className='designation'>{development.designation_id}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </>
            }
            {/* </div> */}
          </div>

        </div>

        <section className='achievement-section'>
          <h1 className='achievement-heading'>Our Achievement</h1>
          <AchievementCarousel />
        </section>

        <section className='media-section'>
          <h1 className='media-heading'>Sumago in media</h1>
          <MediaCarousel />
        </section>
      </div>

      {/* <Footer /> */}
      <NewFooter />
    </>
  )
}
