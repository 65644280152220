import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import About from './Components/About'
// import Services from './Components/Services'
import Career from './Components/Career'
// import Life from './Components/LifeAtSumago'
// import Contact from './Components/Contact'
import Footer from './Components/Footer'
import LandingPage from './Components/LandingPage';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewServicePage from './Components/NewServicePage';
import NewLifeComp from './Components/NewLifeComp';
// import NewLandingPage from './Components/NewLandingPage';
// import { FaWhatsappSquare } from 'react-icons/fa'
// import {IoLogoWhatsapp} from 'react-icons/io'
// import {BsWhatsapp} from 'react-icons/bs'
import 'font-awesome/css/font-awesome.min.css'


function App() {

  // axios.defaults.baseURL = 'http://localhost/main/'

  // axios.defaults.baseURL = 'https://sumagoweb.sumagotest.in/';

  axios.defaults.baseURL = 'https://apiwebsite.sumagoinfotech.com/';

  const phone_no = '+918530388815'

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='whatsapp-logo'>
        <a href={`https://wa.me/${phone_no}`}
          target="_blank" rel="noreferrer"
          className="btn-whatsapp-pulse">
          <i className="fa fa-whatsapp"></i>
        </a>
      </div>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        {/* <Route path='/' element={<NewLandingPage />} /> */}
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<NewServicePage />} />
        <Route path='/career' element={<Career />} />
        {/* <Route path='/life' element={<Life />} /> */}
        <Route path='/life' element={<NewLifeComp />} />
        <Route path='/contact' element={<Footer />} />
      </Routes>
    </div>
  );
}

export default App;
