import React, { useEffect, useState } from 'react'
// import ReactImg from '../Images/React.png'
// import BlockchainImg from '../Images/Blockchain.png'
// import DataAnalystImg from '../Images/Data Analyst.png'
// import DataScienceImg from '../Images/Data Science.png'
// import LaravelImg from '../Images/Laravel.png'
import Slider from 'react-slick';

export default function LifeAtSumagoCarousel({ imagesType, imagesMobile, imagesDesktop }) {

    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 500)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.addEventListener('resize', handleResize)
        }
    }, [])

    // const techImages = [ReactImg, BlockchainImg, DataAnalystImg, DataScienceImg, LaravelImg]

    // let slides;

    // imagesType.length < 3 ? slides = imagesType.length : slides = 3

    // Slick carousel settings
    const settings = {
        // dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1, // Number of photos to show in each slide
        slidesToScroll: 1, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 4000, // Transition speed in milliseconds
        autoplaySpeed: 4000, // Interval between each slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    console.log("Events pics in ImagesType", imagesType);

    return (
        <div>
            {/* <div className='carousel-main'>
                <Slider {...settings}>

                    {techImages.map(a => {
                        return (
                            <div className='d-flex justify-content-center'>
                                <img src={a} alt="" width="50%" />
                            </div>
                        )
                    })}
                </Slider>
            </div> */}

            <div className='carousel-main p-4 pt-0'>
                {imagesDesktop.length > 0 &&
                    <>
                        <Slider {...settings}>

                            {isMobileView ?

                                imagesMobile.map(a => {
                                    return (
                                        <div className='d-flex justify-content-center'>
                                            <img src={a.image} alt="" className='img-fluid' />
                                        </div>
                                    )
                                })
                                : imagesDesktop.map(a => {
                                    return (
                                        <div className='d-flex justify-content-center'>
                                            <img src={a.image} alt="" className='img-fluid' />
                                        </div>
                                    )
                                })}
                        </Slider>
                    </>
                }
            </div>
        </div>
    )
}
