import React from 'react'
import ReactImg from '../Images/ReactNew.png'
import AngularImg from '../Images/AngularNew.png'
import BlockchainImg from '../Images/BlockchainNew.png'
import DataAnalystImg from '../Images/Data AnalystNew.png'
import DataScienceImg from '../Images/Data ScienceNew.png'
import LaravelImg from '../Images/LaravelNew.png'
import "react-multi-carousel/lib/styles.css";
import Slider from 'react-slick';

export default function TechCarousel() {

    const techImages = [
        {
            tech_name: "React",
            tech_image: ReactImg
        },
        {
            tech_name: "BlockChain",
            tech_image: BlockchainImg
        },
        {
            tech_name: "Data Analytics",
            tech_image: DataAnalystImg
        },
        {
            tech_name: "Data Science",
            tech_image: DataScienceImg
        },
        {
            tech_name: "Laravel",
            tech_image: LaravelImg
        },
        {
            tech_name: "Angular",
            tech_image: AngularImg
        }
    ]

    // Slick carousel settings
    const settings = {
        // dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        // slidesToShow: 4, // Number of photos to show in each slide
        // slidesToScroll: 4, // Number of photos to scroll when navigating
        autoplay: true,
        speed: 3000, // Transition speed in milliseconds
        autoplaySpeed: 3000, // Interval between each slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        <div>
            <div className='tech-carousel'>
                <Slider {...settings}>

                    {techImages.map(tech => {
                        return (
                            <>
                                <div className='d-flex justify-content-center'>
                                    <img src={tech.tech_image} alt="" className='tech-image'/>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <p className='tech-name'>{tech.tech_name}</p>
                                </div>
                            </>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
