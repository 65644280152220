import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BsTelephone } from 'react-icons/bs'
import { MdOutlineEmail } from 'react-icons/md'
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

export default function Navbar() {


  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  // const [topic, setTopic] = useState("")
  const [mobile, setMobile] = useState("")
  const [service, setService] = useState("");
  const [address, setAddress] = useState("")
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }
  const captchaRef = useRef(null);
  const onChange = (value) => {
    // This function will be called when the ReCAPTCHA is completed.
    setCaptchaVerified(true);
    console.log(value);
  }
  const SubmitData = (e) => {
    e.preventDefault();

    if (!isCaptchaVerified) {
      setSubmitMessage("Please complete the ReCAPTCHA before submitting.");
      return;
    }

    setIsSubmitting(true);

    setTimeout(() => {
      // Reset the form data, ReCAPTCHA, and submit message.
      setCaptchaVerified(false);
      setSubmitMessage("");
      setIsSubmitting(false);
      // Reset the ReCAPTCHA component using the ref.
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }, 2000);

    let newData = {
      name: name,
      mobile_no: mobile,
      email: email,
      service: service,
      address: address,
      comment: comment,
    };
    console.log("newData", newData);
    axios
      .post(`getAquote/add`, newData)
      .then((resp) => {
        console.log("resp", resp.data);
        toast.success("Quote sent successfully")
        handleClose()
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Quote form rejected")
      });
    setName("");
    setEmail("");
    setMobile("");
    setService("");
    setAddress("")
    setComment("");
  };


  // Perform email validation using a regular expression
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const mobileRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      e.target.setCustomValidity('Email is incorrect.');
    } else {
      e.target.setCustomValidity('');
    }
  };


  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setMobile(truncatedValue);

    if (numericValue.length > 11) {
      e.target.setCustomValidity('Mobile no should be a maximum of 10 digits');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setName(alphabeticValue);
    
    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleServiceChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setService(alphabeticValue);
    
    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-alphabetic characters
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = alphabeticValue;
    setComment(alphabeticValue);
    
    // Check if the input contains only alphabets
    if (inputValue !== alphabeticValue) {
      e.target.setCustomValidity('Message should only contain alphabets.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const [activeLink, setActiveLink] = useState('Home'); // Initialize with the default active link
  const location = useLocation();
  // Function to handle link click and update activeLink state

  useEffect(() => {
    const path = location.pathname;
    // Logic to determine the active link based on the current path
    if (path === '/home') {
      setActiveLink('Home');
    } else if (path === '/about') {
      setActiveLink('About Us');
    } else if (path === '/services') {
      setActiveLink('Services')
    } else if (path === '/career') {
      setActiveLink("Career")
    } else if (path === '/life') {
      setActiveLink("Life@Sumago")
    } else if (path === '/contact') {
      setActiveLink("Contact Us")
    }
  }, [location]);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary mt-2">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-0 ms-md-0">
              <li className="nav-item d-none d-lg-block ms-lg-5 ms-md-0">
                <a href="tel:+91 8530648814" className='text-decoration-none'>
                  <span className="nav-link ms-md-0 fw-bold" aria-current="page" id='span12'><BsTelephone className='me-1' fill='red' />+91 8530648814 +91 8530388815</span>
                </a>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'Home' ? 'text-danger' : ''}`} aria-current="page" to="/home">Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'About Us' ? 'text-danger' : ''}`} to="/about">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'Services' ? 'text-danger' : ''}`} to='/services'>Services</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'Career' ? 'text-danger' : ''}`} to='/career'>Career</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'Life@Sumago' ? 'text-danger' : ''}`} to='/life'>Life@Sumago</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === 'Contact Us' ? 'text-danger' : ''}`} to='/contact'>Contact Us</Link>
              </li>
              <li className="nav-item ms-lg-3 ms-md-0 git-button mt-lg-2 mt-lg-0 p-2">
                <button className='btn git-main-button'
                  onClick={handleShow}>
                  <Link className="nav-link"><MdOutlineEmail fontSize="3rem"
                    className='me-2' />GET IN TOUCH</Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      <div className='gaq-modal'>
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={SubmitData}>
            <Modal.Header closeButton>
              <Modal.Title className="text-dark gaq-text-dark fw-bold">Get Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="mb-3 ">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark gaq-text-dark fw-500"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control gaq-form-control"
                  placeholder="enter your name here"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>

              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark gaq-text-dark fw-500"
                >
                  Mobile No.
                </label>
                <input
                  type="text"
                  className="form-control gaq-form-control "
                  id="exampleFormControlInput1"
                  placeholder="ex. 90xxxxxx"
                  value={mobile}
                  onChange={handleMobileChange}
                  required
                />
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark gaq-text-dark fw-500"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control gaq-form-control "
                  placeholder="test@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-dark gaq-text-dark fw-500"
                >
                  Type Of Service
                </label>
                <input
                  type="text"
                  className="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                  id="exampleFormControlInput1"
                  placeholder="services"
                  value={service}
                  onChange={handleServiceChange}
                  required
                />
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark gaq-text-dark fw-500"
                >
                  Address
                </label>
                <textarea
                  class="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  placeholder="address"
                  rows="1"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                ></textarea>
              </div>

              <div class="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  class="form-label text-dark gaq-text-dark fw-500"
                >
                  Comment
                </label>
                <textarea
                  class="form-control gaq-form-control text-dark gaq-text-dark fw-500"
                  id="exampleFormControlTextarea1"
                  placeholder="any comment"
                  rows="1"
                  value={comment}
                  onChange={handleCommentChange}
                  required
                ></textarea>
                 <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6Lc-cwEpAAAAAIln-wa3CB5qrsM7DGh7FG6icrYj"
                    onChange={onChange} className='mt-4'
                  />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
              {isSubmitting ? "Submitted" : "Submit"}
                </Button>
                {submitMessage && <p>{submitMessage}</p>}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  )
}
