import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
// import Footer from '../Components/Footer'
// import axios from 'axios'
import LifeAtSumagoCarousel from './LifeAtSumagoCarousel'
import '../Styles/NewLifeComp.css'
import NewFooter from './NewFooter'
import Fun from '../Images/Life@WorkImages/Fun.png'
import FunImage from '../Images/Life@WorkImages/FunImage.png'
import News from '../Images/Life@WorkImages/Media.png'
import NewsImage from '../Images/Life@WorkImages/NewsImage.png'
import Recognition from '../Images/Life@WorkImages/Recognition.png'
import RecognitionImage from '../Images/Life@WorkImages/RecognitionImage.png'
import axios from 'axios'

export default function NewLifeComp() {

    const [funAtWorkMobile, setFunAtWorkMobile] = useState([])
    const [funAtWorkDesktop, setFunAtWorkDesktop] = useState([])
    const [recognitionMobile, setRecognitionMobile] = useState([])
    const [recognitionDesktop, setRecognitionDesktop] = useState([])
    const [newsMobile, setNewsMobile] = useState([])
    const [newsDesktop, setNewsDesktop] = useState([])


    useEffect(() => {
        axios.get('/funatwork/get-all-record')
        .then(res => {
            console.log("res.data in funatWork", res.data);
            const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
            console.log("data in mobileType", mobileImage);
            setFunAtWorkMobile(mobileImage)

            const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
            console.log("data in desktopType", desktopImage);
            setFunAtWorkDesktop(desktopImage)
        }).catch(err => {
            console.log(err);
        })

        axios.get('/recognisation/get-all-record')
        .then(res => {
            console.log("res.data in recognition", res.data);
            const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
            console.log("data in mobileType", mobileImage);
            setRecognitionMobile(mobileImage)

            const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
            console.log("data in desktopType", desktopImage);
            setRecognitionDesktop(desktopImage)
        }).catch(err => {
            console.log(err);
        })

        axios.get('/news/get-all-record')
        .then(res => {
            console.log("res.data in news", res.data);
            const mobileImage = res.data.filter(a => a.device_type === 'Mobile')
            console.log("data in mobileType", mobileImage);
            setNewsMobile(mobileImage)

            const desktopImage = res.data.filter(a => a.device_type === 'Desktop')
            console.log("data in desktopType", desktopImage);
            setNewsDesktop(desktopImage)
        }).catch(err => {
            console.log(err);
        })
      }, [])

    const events = [
        FunImage, FunImage, FunImage, FunImage
    ]
    const training = [RecognitionImage, RecognitionImage, RecognitionImage, RecognitionImage]
    const awards = [NewsImage, NewsImage, NewsImage, NewsImage]


    // useEffect(() => {
    //     axios.get(`events/get-all-record`)
    //         .then(res => {
    //             console.log("events in life@sumago", res.data);
    //             setEvents(res.data)
    //         }).catch(err => {
    //             console.log(err);
    //         })

    //     axios.get(`/training/get-all-record`)
    //         .then(res => {
    //             console.log("funWork@Sumago", res.data);
    //             setTraining(res.data)
    //         }).catch(err => {
    //             console.log(err);
    //         })

    //     axios.get(`/award/get-all-record`)
    //         .then(res => {
    //             console.log("Awards", res.data);
    //             setAwards(res.data)
    //         }).catch(err => {
    //             console.log(err);
    //         })
    // }, [])

    return (
        <>
            <div className='life-main-new'>
                <Header />

                <div className='row fun'>
                    <div className='col-2'>
                        <img src={Fun} alt="" className='samecomp img-fluid' />
                    </div>
                    <div className="col-10">
                        <LifeAtSumagoCarousel 
                        imagesType={events}
                        imagesDesktop={funAtWorkDesktop}
                        imagesMobile={funAtWorkMobile}
                        />
                    </div>
                </div>
                <div className='row mt-2 fun'>

                    <div className="col-10">
                        <LifeAtSumagoCarousel 
                        imagesType={training} 
                        imagesDesktop={recognitionDesktop}
                        imagesMobile={recognitionMobile}
                        />
                    </div>
                    <div className='col-2'>
                        <img src={Recognition} alt="" className=' img-fluid samecomp' />
                    </div>
                </div>
                <div className='row mt-2 fun'>
                    <div className='col-2'>
                        <img src={News} alt="" className=' samecomp img-fluid' />
                    </div>
                    <div className="col-10">
                        <LifeAtSumagoCarousel 
                        imagesType={awards} 
                        imagesDesktop={newsDesktop}
                        imagesMobile={newsMobile}
                        />
                    </div>
                </div>

                {/* <Footer /> */}
                <NewFooter />
            </div>
        </>
    )
}
