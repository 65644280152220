import React, { useState } from "react";
import '../Styles/Landingpage.css'
// import LandingPageBanner from '../Images/LandingPageBannerImage.png'
import MainLandingImage from '../Images/CommonLandingImage2.png'
import ScopeMainImage from '../Images/ScopeMainImage.png'
import SumagoMainImage from '../Images/SumagoWebMainImage.png'
import SumagoMainImage2 from '../Images/LandingPageBlack1.png'
import ScopeMainImage2 from '../Images/LandingPageBlack2.png'
import BuildingMobile from '../Images/NewLandingPageImages/BuildingMobile.png'
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function LandingPage() {

    const navigate = useNavigate()

    const redirectToExternalWebsite = () => {
        // Use the history object to redirect to an external website
        window.location.href = 'http://sumagotraining.in';
    };

    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
        setIsFlipped(true);
    };

    const handleMouseLeave = () => {
        setIsFlipped(false);
    };

    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.addEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="LandingPage-main">
            {/* <div className="banner-main">
                <div className="banner-background">
                    <img src={MainLandingImage} alt="" className="img-fluid" />
                </div>
                <div className="webandtraining row">
                    <div className="col-md-6 col-12 d-flex justify-content-center align-items-end">
                        <img src={SumagoMainImage} alt="" className="img-fluid"
                            onClick={() => navigate('/home')} />
                    </div>
                    <div className="col-md-6 col-12 d-flex justify-content-center align-items-end">
                        <img src={ScopeMainImage} alt="" className="img-fluid"
                            onClick={redirectToExternalWebsite} />
                    </div>
                </div>
            </div> */}

            <div className={`banner-main ${isMobileView ? 'row' : ''}`}>
                <div className="banner-background col-6">
                    {isMobileView ? 
                    <img src={BuildingMobile} alt="" className="img-fluid" />
                    :
                    <img src={MainLandingImage} alt="" className="img-fluid" />
                }
                </div>
                <div className="webandtraining row col-6">
                    <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-end">
                        <div className={`flip-card ${isFlipped ? 'flipped' : ''} flip-card-1`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="flip-card-inner"
                                onClick={() => navigate('/home')}>
                                <div className="flip-card-front">
                                    <img src={SumagoMainImage} alt="web" className="img-fluid" />
                                </div>
                                <div className="flip-card-back black-background">
                                    <img src={SumagoMainImage2} alt="web" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-end">
                        <div className={`flip-card ${isFlipped ? 'flipped' : ''} flip-card-2`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="flip-card-inner"
                                onClick={redirectToExternalWebsite}>
                                <div className="flip-card-front">
                                    <img src={ScopeMainImage} alt="web" className="img-fluid" />
                                </div>
                                <div className="flip-card-back black-background">
                                    <img src={ScopeMainImage2} alt="web" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage